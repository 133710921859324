<template>
  <v-row v-if="hasGeneratedEntries" align="center">
    <v-col>
      <v-card class="add-link" color="transparent" elevation="0" :loading="deleteGeneratedLoading"
        :disabled="deleteGeneratedLoading" @click="deleteGenerated">
        <v-card-text class="text-center text-primary text-body-1">
          <v-icon>mdi-delete-circle-outline</v-icon>
          <span class="text-body-1 pt-2 pl-2">Automatisch generierte Einträge löschen</span>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="hasRole(['ROTATING_STAFF'])" cols="auto">
      <app-alert alert="generated_entries">
        <div class="text-h2 mb-5">Es wurden Einträge automatisch generiert</div>
        <div class="text-body-1 font--text">
          Durch klicken auf die Karte kann der jeweilige Eintrag bearbeitet werden.
        </div>
      </app-alert>
    </v-col>
  </v-row>
  <v-row v-if="!hasOtherEntries && hasRole(['ADMINISTRATIVE_STAFF'])">
    <v-col>
      <v-card color="transparent" data-cy="addExperienceDuration" elevation="0">
        <v-card-text class="text-center text-primary text-body-1 px-0">
          <v-container class="px-0">
            <v-form ref="monthsForm">
              <v-row>
                <v-col>
                  <v-text-field v-model="months" label="Weiterbildungszeit in Monaten" variant="outlined" type="number"
                    step="1" hide-details="auto" :rules="monthsErrors"></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-tooltip text="Beispielhafte Erfahrungen auf Basis des Rotationscurriculums generieren">
                    <template #activator="{ props }">
                      <v-btn v-bind="props" append-icon="mdi-auto-fix" color="primary" rounded class="mt-2"
                        :loading="loading" @click="addDurationEntries">
                        Erfahrung generieren
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    entries: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change'],
  data() {
    return {
      months: null,
      monthsErrors: [(v) => !!v],
      loading: false,
    }
  },
  computed: {
    hasGeneratedEntries() {
      return this.entries.some((entry) => entry.status === 'generated')
    },
    hasOtherEntries() {
      return this.entries.some((entry) => entry.status !== 'generated')
    },
  },
  methods: {
    async addDurationEntries() {
      this.$refs.monthsForm.validate().then(async (res) => {
        if (res.valid) {
          this.loading = true
          await this.deleteGenerated(false)
          await this.$cms.request(() => {
            return {
              method: 'POST',
              path: '/custom-schedule/history/default',
              body: JSON.stringify({
                user: this.userId,
                months: this.months,
              }),
            }
          })
          this.$emit('change')
          this.loading = false
        }
      })
    },
    async deleteGenerated(emit = true) {
      this.deleteGeneratedLoading = true
      const entriesToDelete = this.entries.filter((entry) => entry.status === 'generated')
      if (entriesToDelete.length) {
        await this.$cms.request(
          this.$deleteItems(
            'schedule_history',
            entriesToDelete.map((entry) => entry.id)
          )
        )
        if (emit) {
          this.$emit('change')
        }
      }
      this.deleteGeneratedLoading = false
    },
  },
}
</script>
